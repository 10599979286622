<template>
  <div>
    <Anchor :affix="false" container="#container">
      <AnchorLink href="#22" title="Basic Usage" />
    </Anchor>
    <div id="container">
      <Scroll
            ref="scroll"
              height="800"
              :distance-to-edge="10"
              loading-text="正在加载数据，请稍等..."
            >
        <Card :id="item" dis-hover v-for="(item, index) in list" :key="index" style="margin: 32px 0">
            Content {{ item }}
        </Card>   
      </Scroll>
    </div>
  </div>
</template>

<script>
//@route('/testanchor')
export default {
  data() {
    return {
        list: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26],
    };
  },
};
</script>
    
<style scoped lang='less'>
    #container {
        height: calc(100vh - 30px);
        position: relative;
        overflow-y: scroll;
    }
    /deep/.ivu-anchor-ink {
  display: none;
}
</style>